<!-- =========================================================================================
    File Name: PortalProjects.vue
    Description: Listing des projets d'un portal
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative
    Author: Loïc ROYBON
    Author URL:
========================================================================================== -->

<template>
    <div id="projects-cards" v-if="loaded">
      <!-- keyword selector -->
      <div v-if="projectsKeywords.length > 0" class="flex">
        <vs-chip color="primary" transparent closable @click="keywordQuery = ''">
          <vs-avatar icon-pack="feather" icon="icon-hash" />
          <vs-dropdown class="cursor-pointer">
            <h4 class="flex" href="#">
              <span class="ml-1">{{keywordQuery || $t('All')}}</span> 
              <div class="ml-2 flex">
                <feather-icon icon="ChevronDownIcon" svgClasses="h-6 w-6 text-dark" />
              </div>
            </h4>
            <div class="w-8/10 cursor-pointer">
              <vs-dropdown-menu class="w-full" v-if="projectsKeywords.length > 0">
                <vs-dropdown-item v-for="keyword in projectsKeywords" :key="keyword" @click="keywordQuery = keyword" :disabled="keywordQuery === '' && !projectsFilteredKeywords.includes(keyword)">
                  {{keyword}}
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </div>
          </vs-dropdown>
        </vs-chip>
      </div>
      <vs-input icon-no-border :placeholder="$t('Search')" v-model="searchQuery" class="w-full input-rounded-full mt-3" icon="icon-search" icon-pack="feather" />

      <!-- PUBLIC GALLERY -->
      <template v-if="!loggedIn || !can_see_page">
        <!-- login form -->
        <template v-if="!loggedIn">
          <div class="mt-2">
            <p class="mt-2">{{$t('loginRequiredMessage')}}</p>
            <!-- <vs-alert color="danger" class="mt-5" icon-pack="feather" icon="icon-rotate-cw">{{$t('migrationLoginMessage')}}</vs-alert> -->
            <vs-button class="mt-4" @click="login" color="primary">{{$t('loginOrRegister')}}</vs-button>
          </div>
        </template>
        <!-- invalid account -->
        <template v-if="loggedIn && privatePortal">
          <vs-alert color="warning" :title="this.$t('privatePortal')" active="true">
            {{ $t('PortalAccessDeniedFull') }}.
          </vs-alert>
          <vx-card remove-card-action :title="$t('youHaveAnInviteTitle')" content-color="dark" class="mt-4">
            <p>{{$t('youHaveAnInviteMessage')}}</p>
            <vs-input v-model="shareCode" @blur="gotoShare" @keyup.enter="gotoShare" class="w-full"></vs-input>
          </vx-card>
        </template>
        <vs-divider/>

        <!-- public view -->
        <h4>{{$t('publicGalleryTitle')}}</h4>
        <p>{{$t('publicGalleryMessage')}} {{portal_nicename}}</p>
        <div class="vx-row mt-6">
          <vs-alert v-if="projectsFiltered.length === 0" color="warning" active="true" class="mt-5 text-warning">
            {{ $t('emptyFilteredProjectsList') }}
          </vs-alert>
          <template v-else-if="this.projects.length < 1">
            <vs-alert v-if="currentUserCanSeeAllProjects" color="warning" active="true" class="mt-5 text-warning">
              {{ $t('noProjectInPortal') }}
            </vs-alert>
            <vs-alert v-else color="warning" active="true" class="mt-5 text-warning">
              {{ $t('noAuthorizedProjectInPortal') }}
            </vs-alert>
          </template>

          <div class="vx-col w-full xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-base"
            v-for="project in projectsFiltered"
            :key="'project_'+project.project_id+'_'+project.project_slug"
            :title="project.project_slug">
            <!-- Project Card -->
            <project-card :project="project" :forceAccessiblity="true"></project-card>
          </div>
        </div>
      </template>

      <!-- LOGGEDIN WITH VALID ACCOUNT ZONE -->
      <template v-else>
        <vs-progress v-if="!loaded && !privatePortal" indeterminate color="primary"></vs-progress>
        <div v-if="loaded && !privatePortal">
          <template v-if="!this.portalExists">
            <vs-alert color="warning" :title="this.$t('Error')" active="true">
              <p>{{ $t('invalidPortalSlug') }}</p>
            </vs-alert>
          </template>
          <template v-else>
            <div v-if="can_see_page" class="mt-5">

              <!-- nouveau projet --> 
              <subscription-quotas/>
              <vs-row v-if="this.$store.state.current_user_portal_caps.can_portal_projects_manage === '1'"
              vs-type="flex" vs-justify="flex-end">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="5">
                  <vs-button v-if="this.$store.state.subscriptionUsage.projectsAvailable > 0" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="newProjectConfirm()">{{ $t('NewProject') }}</vs-button>
                  <vs-alert v-else color="warning" active="true">{{ $t('subscriptionProjectsExceeded') }}</vs-alert>
                </vs-col>

                <!-- formulaire -->
                <vs-popup :title="$t('newProjectFormTitle')" :active.sync="newProjectFormActive">
                  <vs-input class="f-width inputx mb-8" 
                    :success="slugAvailable === 'yes'" 
                    :danger="slugAvailable === 'no'" 
                    :success-text="$t('projectSlugAvailable')"
                    :danger-text="$t('projectSlugUnavailable')"
                    val-icon-success="icon-check"
                    val-icon-pack="feather"
                    val-icon-danger="icon-x"
                    v-model="newProjectSlug"  
                    @blur="projectSlugAvailable()" 
                    @keydown.enter="projectSlugAvailable()" 
                    :label-placeholder="$t('newProjectSlug')"/>
                  <vs-input class="f-width inputx mb-8" 
                    :success="newProjectNicename.length > 2" 
                    val-icon-success="icon-check"
                    val-icon-pack="feather"
                    v-model="newProjectNicename" 
                    :label-placeholder="$t('newProjectNicename')"/>
                  <vs-button 
                    @click="newProjectCreation" 
                    :disabled="!newProjectCreationPossible()" 
                    color="primary" 
                    type="filled"
                    ref="loadableButton" id="button-with-loading" class="f-width inputx mb-8 vs-con-loading__container"
                    >{{ $t('newProjectBtn') }}
                  </vs-button>
                </vs-popup>
                
              </vs-row>

              <vs-tabs alignment="fixed">
                <!-- Project list -->
                <vs-tab :label="`${$t('projectsList')} (${this.numberOfProjectsOnList})`" icon-pack="feather" icon="icon-list">
                  <div class="btn-group flex w-full py-2">
                    <label class="mt-4 mr-4 text-xs">{{$t('sortBy')}} :</label>
                    <vs-button v-for="i in sortOptions" :key="'sort_'+i.value" 
                    size="small" icon-pack="feather" :icon="i.icon" type="line" class="p-0"
                    @click="sortProjectsList(i.value)">{{$t(i.label)}}</vs-button>
                  </div>

                  <div class="vx-row mt-6">
                    <vs-alert v-if="projectsFiltered.length === 0" color="warning" active="true" class="mt-5 text-warning">
                      {{ $t('emptyFilteredProjectsList') }}
                    </vs-alert>
                    <template v-else-if="this.projectsFiltered.length < 1">
                      <vs-alert v-if="currentUserCanSeeAllProjects" color="warning" active="true" class="mt-5 text-warning">
                        {{ $t('noProjectInPortal') }}
                      </vs-alert>
                      <vs-alert v-else color="warning" active="true" class="mt-5 text-warning">
                        {{ $t('noAuthorizedProjectInPortal') }}
                      </vs-alert>
                    </template>

                    <div v-for="project in projectsFiltered" :key="'project_'+project.project_id+'_'+project.project_slug"
                      class="vx-col w-full xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-base">
                      <!-- Project Card -->
                      <project-card :project="project" :forceAccessiblity="isProjectAdmin || isCollaborator != true"></project-card>
                    </div>
                  </div>
                </vs-tab>

                <!-- Project map -->
                <vs-tab v-if="this.$store.state.projectsLocation" :label="`${$t('projectsMap')} (${this.numberOfProjectsOnMap})`" icon-pack="feather" icon="icon-map-pin">
                  <div class="w-full">   
                    <vs-alert v-if="numberOfProjectsWithoutPosition > 0" color="warning" icon-pack="feather" icon="icon-info">{{$t('someProjectsWithoutPosition')}}
                      <li v-for="p in projectsWithoutPositionFiltered" :key="p.project_id">
                        <a class="no_position_project_link" :href="'/project/'+p.project_slug">{{decodeURIComponent(p.project_nicename)}}</a>
                      </li> 
                    </vs-alert>     
                    <template v-if="this.numberOfProjectsOnMap > 0">
                      <div :key="'map_'+query">
                        <gmap-map :center="markersFilteredLimits.center" :zoom="this.map_zoom" :style="this.map_style" map-type-id="satellite">
                          <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                            <div v-html="infoContent"></div>
                          </gmap-info-window>
                          <template v-if="mapBuilt">
                            <template v-for="(m,i) in markersFiltered">
                              <template v-if="m">
                                <gmap-marker :key="i" :position="m.position" :clickable="true" @click="toggleInfoWindow(m,i)"></gmap-marker>
                              </template>
                            </template>
                          </template>
                        </gmap-map>
                      </div>
                    </template>
                    <template v-else>
                      <vs-alert color="warning" active="true" class="mt-5 text-warning">
                        {{ $t('noProjectPositioned') }}
                      </vs-alert>
                    </template>
                  </div>
                </vs-tab>


              </vs-tabs>
            </div>
            <div v-else>
              <vs-alert color="warning" :title="$t('AccessDeniedTitle')" active="true">
                <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
              </vs-alert>
            </div>
          </template>
        </div>
      </template>
    </div>
</template>

<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison
import SubscriptionQuotas from './subscription/quotas.vue'
import ProjectCard from './project/projectCard.vue'
import logActivity from '@/logActivity.js'
import vSelect from 'vue-select'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  components: {
    SubscriptionQuotas, vSelect, ProjectCard
  },

  data () {
    return {
      portal: null,
      portal_id:        '',
      portal_slug:      '',
      portal_nicename:  '',
      project:  {},
      projects: {},
      projectsLocation: '',
      projectsLegend:  '',
      projectsCoordinatesSystem:  '',
      betaTester:  '',
      rdp:  '',
      viewerVersion:  '',
      shareCode: '',

      // ROYBON / Tri
      projectOrderBy: 'project_id',
      projectOrderSens: 'DESC',
      sortOptions: [
        { label: 'mostRecentFirst', value: 'mostRecentFirst', icon:'icon-sunrise' },
        { label: 'alphabeticalSort', value: 'alphabeticalSort', icon:'icon-list'  },
        { label: 'publicFirst', value: 'publicFirst', icon:'icon-globe'  }
      ],
      selectedSortOption: 'mostRecentFirst',
      keywordQuery: '',
      searchQuery: '',
      selectedKeyword: '',

      // ROYBON / Création d'un projet$
      newProjectFormActive : false,
      newProjectSlug : '',
      newProjectNicename : '',
      slugAvailable: '',


      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalIdFromSlug: 'getPortalIdFromSlug',
      getPortalProjectsInfos: 'getPortalProjectsInfos',
      getPortalProjectsInfosWithUserAccessibility: 'getPortalProjectsInfosWithUserAccessibility',
      getUserAuthorizedPortalProjects: 'getUserAuthorizedPortalProjects',
      getUserGlobalCaps: 'getUserGlobalCaps',
      getProjectBySlug: 'getProjectBySlug',
      createPortalProjectWithUser: 'createPortalProjectWithUser',

      // ROYBON / Droits du user stockés localement
      current_user_global_caps: this.$store.state.current_user_global_caps,
      current_user_portal_caps: this.$store.state.current_user_portal_caps,
      userPortalCapsRefreshed: false,

      // ROYBON / Interprétation des droits
      can_see_page: false,
      AccessDeniedMessage: '',
      AccessDeniedInfos: '',
      currentUserCanSeeAllProjects: false,

      // ROYBON / Etat de chargement de la page
      portalExists: null,
      loaded: false,

      // ROYBON / Google Maps
      mapBuilt: false,
      map_style : {
        width: '100%',
        height: 'calc(90vh - 200px)',
        minHeight: '430px'
      },
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      projectsWithoutPosition: {},
      showNoPositionAlert: false,

      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: { width: 0, height: -35 }
      },
      map_zoom: 3,
      eiffel_lat: 48.858053,
      eiffel_lng:  2.294289,
      jdata:{},
      center: { 
        lat: 48.858053, 
        lng: 2.294289 
      },
      markers: []
    }
  },
  methods: {
    // ROYBON / Use share code
    gotoShare () {
      let shareCode = this.shareCode
      if (shareCode.includes('/')) {
        shareCode = shareCode.substring(shareCode.lastIndexOf('/') + 1)
      }
      shareCode = shareCode.toLowerCase().trim().replace(/\s/g, '')
      if (shareCode !== '') this.$router.push(`/s/${shareCode}`).catch(() => {})
    },
    // ROYBON / Login
    login () {
      sessionStorage.setItem('loginRedirect', this.$route.fullPath)
      this.$auth.login({ target: this.$route.fullPath })
    },
    // ROYBON / Tri des projets 
    sortProjectsList (sortType) {
      if (sortType === 'alphabeticalSort') {
        this.projectOrderBy = 'project_nicename'
        this.projectOrderSens = 'ASC'
      } else if (sortType === 'publicFirst') {
        this.projectOrderBy = 'project_status'
        this.projectOrderSens = 'DESC'
      } else if (sortType === 'mostRecentFirst') {
        this.projectOrderBy = 'project_id'
        this.projectOrderSens = 'DESC'
      }
    },
    // ROYBON / Active ou non la possibilité de créer le projet
    newProjectCreationPossible () {
      let returned = false 
      if (this.slugAvailable === 'yes') {
        if (this.newProjectSlug.length > 2 && this.newProjectNicename.length > 2) {
          returned = true
        } else {
          returned = false
        }
      }
      return returned
    },
    // ROYBON / Changement du slug du projet 
    projectSlugAvailable () {
      this.slugAvailable = ''
      if (this.newProjectSlug.length > 0) {
        // sanitize
        this.newProjectSlug = this.urlify(this.newProjectSlug)
        let returned = true
        const slug = this.urlify(this.newProjectSlug)
        const slug_hash = md5(sha1(slug).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
        const params = `project_slug=${slug}&slug_hash=${slug_hash}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getProjectBySlug}?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            // project exists
            if (response.data !== null && response.data != 'null') {
              // and it's not the current project
              if (response.data.project_id != 'null') {
                returned = false
              } else {
                returned = true
              }
            } else {
              returned = true
            }
            returned === true ? this.slugAvailable = 'yes' : this.slugAvailable = 'no'
            return returned
          })
          .catch((error)   => { console.log(error) })
      }
    },
    // ROYBON / Fonction de formattage de string pour URL
    urlify (value) {
      if (!value) return ''
      value = value.toString()
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/[^0-9a-z]/gi, '-')
      value = value.replace(/ /g, '-')
      value = value.split('--').join('-')
      value = value.substring(0, 15)
      value = value.replace(/-/gi, ' ')
      value = value.trim()
      value = value.replace(/ /gi, '-')
      value = value.toLowerCase()
      value = value.split('--').join('-')
      return value
    },
    // ROYBON / Affichage du formulaire de création de projet
    newProjectForm () {
      this.newProjectFormActive = true
    },
    // ROYBON / Début de création de projet
    newProjectConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.$t('ConfirmProjectCreation'),
        text: `${this.$store.state.subscriptionUsage.projectsAvailable} ${this.$t('projectsAvailableOnPlan')}. ${this.$t('Confirm')} ?`,
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.newProjectForm
      })
    },
    // ROYBON / Lance la création du projet
    newProjectCreation () {
      this.$vs.loading({
        background: 'primary',
        color: '#fff',
        container: '#button-with-loading',
        scale: 0.45
      })

      // Création d'un nouveau projet et ajout d'un user avec tous les droits sur ce projet
      const newProjectSlugUrlEncoded = encodeURIComponent(this.newProjectSlug)
      const newProjectNicenameEncoded = encodeURIComponent(this.newProjectNicename)
      //const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.$store.state.AppActiveUser.user_id}&project_slug=${newProjectSlugUrlEncoded}&project_nicename=${newProjectNicenameEncoded}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('user_id', `${this.$store.state.AppActiveUser.user_id}`)
      params.set('project_slug', `${newProjectSlugUrlEncoded}`)
      params.set('project_nicename', `${newProjectNicenameEncoded}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.createPortalProjectWithUser}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => {         
          if (!isNaN(response.data)) {
            this.getProjectsList()
            this.$vs.loading.close()
            this.newProjectFormActive = false
            this.$vs.notify({
              color:'success',
              title:this.$t('projectCreationSuccess')
            })            
            // log l'activité du user
            logActivity.add('portal_project_creation', 'success', null, this.portal_id, response.data, null)
            // redirect
            this.$router.push({ path: `/project/${newProjectSlugUrlEncoded}`})

          } else {
            this.$vs.loading.close()
            this.newProjectFormActive = false
            this.$vs.notify({
              color:'danger',
              title:this.$t('projectCreationError')
            })
          }
        })
        .catch((error)   => { console.log(error) })

      setTimeout(() => {
        this.$vs.loading.close('#button-with-loading > .con-vs-loading')
      }, 3000)
    },
    // ROYBON / Update portal infos
    updatePortalInfos () {
      let params = ''
      let rimnat_api_call_url = ''
      // ROYBON / Modifie la valeur globale portal_slug dans le store et dans la vue
      this.portal_slug = this.$route.params.portal_slug
      this.$store.commit('UPDATE_PORTAL_SLUG', this.portal_slug)
      this.$store.dispatch('updatePortalSlug', this.portal_slug)
      // ROYBON / Récupère le portal_id associé au portal_slug passé en URL
      params = `portal_slug=${this.$route.params.portal_slug}`
      rimnat_api_call_url = `${this.api_server_baseurl}/public/getPortalBySlug?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (response.data != null && response.data != 'null') {
              if (!isNaN(response.data.portal_id)) {
                this.portalExists = true
                this.portal = response.data
                this.$store.dispatch('updatePortalParams', this.portal)
                this.portal_id = response.data.portal_id
                this.$store.dispatch('updatePortalId', this.portal_id)
                this.portal_nicename = response.data.portal_nicename
                this.$store.dispatch('updatePortalNicename', this.portal_nicename)
                this.projectsLocation = response.data.projects_location == '1'
                this.$store.dispatch('updateProjectsLocation', this.projectsLocation)
                this.projectsLegend = response.data.projects_legend == '1'
                this.$store.dispatch('updateProjectsLegend', this.projectsLegend)
                this.projectsCoordinatesSystem = response.data.projects_coordinates_system == '1'
                this.$store.dispatch('updateProjectsCoordinatesSystem', this.projectsCoordinatesSystem)
                this.betaTester = response.data.beta_tester == '1'
                this.$store.dispatch('updateBetaTester', this.betaTester)
                this.rdp = response.data.rdp == '1'
                this.$store.dispatch('updateRdp', this.rdp)
                this.viewerVersion = response.data.viewer_version
                this.$store.dispatch('updateViewerVersion', this.viewerVersion)
                logActivity.add('portal_access', 'success', null, this.portal_id)
                this.loaded = true
              } else {
                this.portalExists = false
                this.loaded = true
              }
            } else {
              this.portalExists = false
              this.loaded = true
              this.$router.push('/pages/error-404').catch(() => {})
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Update les global_caps du user
    updateUserGlobalCaps () {
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserGlobalCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.current_user_global_caps = response.data 
            this.$store.commit('UPDATE_CURRENT_USER_GLOBAL_CAPS', this.current_user_global_caps)
            this.$store.dispatch('updateCurrentUserGlobalCaps', this.current_user_global_caps)
          }
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Update les portal_caps du user
    getUserPortalCaps () {
      this.current_user_portal_caps = null
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&portal_id=${this.$store.state.portal_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/getUserPortalCaps?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.current_user_portal_caps = response.data 
            this.$store.commit('UPDATE_CURRENT_USER_PORTAL_CAPS', this.current_user_portal_caps)
            this.$store.dispatch('updateCurrentUserPortalCaps', this.current_user_portal_caps)
          }
          this.userPortalCapsRefreshed = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Update projects list
    getProjectsList () {
      let apiName = ''
      let params = `portal_id=${this.portal_id}`
      // teste si user peut voir TOUS les projets du portail ou juste ceux auxquels il accède
      if (typeof this.current_user_portal_caps !== undefined) {
        if (this.isProjectAdmin) {
          this.currentUserCanSeeAllProjects = true
          apiName = 'portal/getPortalProjectsInfos'
          params = `${params}&user_id=${this.$store.state.AppActiveUser.user_id}`
        } else if (this.isCollaborator) {
          this.currentUserCanSeeAllProjects = true
          apiName = 'portal/getPortalProjectsInfosWithUserAccessibility'
          params = `${params}&user_id=${this.$store.state.AppActiveUser.user_id}`
        } else if (this.$store.state.AppActiveUser.user_id !== null && !isNaN(this.$store.state.AppActiveUser.user_id)) {
          this.currentUserCanSeeAllProjects = false
          apiName = 'user/getUserAuthorizedPortalProjects'
          params = `${params}&user_id=${this.$store.state.AppActiveUser.user_id}`
        } else {
          // public gallery
          this.currentUserCanSeeAllProjects = false
          apiName = 'public/getPublicPortalProjects'
          params = `${params}&order_by=${this.projectOrderBy}&order_sens=${this.projectOrderSens}`       
        }
      }
      if (apiName !== '' && params !== '') {
        params = `${params}&portal_id=${this.portal_id}&order_by=${this.projectOrderBy}&order_sens=${this.projectOrderSens}`       
        const rimnat_api_call_url = `${this.api_server_baseurl}/${apiName}?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data != null && response.data != 'null') this.projects = response.data 
          })
          .catch((error)   => { console.log(error) })
      }
    },

    // ROYBON / Redirection vers le projet cliqué sur la map
    goToProject (project) {
      this.$router.push(`/project/${project.project_slug}`)
    },

    // ROYBON / Check si l'affichage de la liste des groupes est autorisé
    canSeePage () {
      if (this.portalExists) {
        this.can_see_page = true
        const gc = this.$store.state.current_user_global_caps
        const pc = this.$store.state.current_user_portal_caps
        if (typeof gc !== undefined && typeof pc !== undefined) {
          if (gc !== null && pc !== null) {
            const start_date = this.$store.state.current_user_portal_caps.can_portal_access_start_date
            const end_date = this.$store.state.current_user_portal_caps.can_portal_access_end_date
            if (gc.can_global_access === '1' && pc.can_portal_access === '1') {
              if (moment().isSameOrBefore(start_date)) {
                this.can_see_page = false
                this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
                this.AccessDeniedInfos = start_date
              } else if (moment().subtract(1, 'd').isSameOrAfter(end_date)) {
                this.can_see_page = false
                this.AccessDeniedMessage = 'PortalAccessExpired'
                this.AccessDeniedInfos = end_date
              } else {
                this.can_see_page = true
              }
            } else {
              this.can_see_page = false
              this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
            }
          } else {
            this.can_see_page = false
            this.AccessDeniedMessage = 'invalidPortalSlug' 
          }
        }
      }
    },

    // ROYBON / Charge les projets sur la carte
    loadProjectsOnMap () {
      if (this.projectsFiltered) {
        for (const p_id in this.projectsFiltered) {
          const p = this.projectsFiltered[p_id]
          const project_id = p.project_id
          const project_slug = p.project_slug
          
          if (typeof p.project_lat === 'string' && typeof p.project_lng === 'string') {
            if (p.project_lat !== '0' && p.project_lat !== 'null' && p.project_lat !== null) {
              let project_lat = ''
              let project_lng = ''
              
              // const project_slug = p.project_slug
              if (p.project_lat.length > 1 && p.project_lng.length > 1) {
                project_lat = p.project_lat
                project_lng = p.project_lng
                if (!isNaN(project_lat) && !isNaN(project_lng) && project_lat !== 'null' && project_lng !== 'null') {
                  const project_nicename = decodeURIComponent(p.project_nicename)
                  const infotext = `
                  <div class='infobox'>
                    <a target='_blank' href='/project/${project_slug}'>
                      <h5>${project_nicename}</h5>
                      <img src="${this.$appConfig.imgBaseUrl}/project/cover/${project_id}/thumb_1000_300.jpg"/>
                    </a> 
                  </div>
                  `
                  const markerObj = {
                    project_id: p.project_id,
                    keywords: p.keywords,
                    position: {
                      lat: parseFloat(project_lat),
                      lng: parseFloat(project_lng)
                    },
                    infotext: infotext
                  }
                  this.$set(this.markers, project_id, markerObj)
                } else {
                  this.$set(this.projectsWithoutPosition, project_id, p)
                }
              } else {
                this.$set(this.projectsWithoutPosition, project_id, p)
              }
            } else {
              this.$set(this.projectsWithoutPosition, project_id, p)
            }
          }  else {
            this.$set(this.projectsWithoutPosition, project_id, p)
          }     
        }
        this.mapBuilt = true
      }
    },
  
    // ROYBON / Infobox Google Maps
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker.infotext
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        //if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    // ROYBON / Fonction de nettoyage d'une query
    sanitizeQuery (value) {
      if (!value) return ''
      value = value.toString()
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/[^0-9a-z]/gi, '-')
      value = value.replace(/-/gi, '')
      value = value.replace(/ /gi, '-')
      value = value.toLowerCase()
      value = value.split('-').join('')
      value = value.split('   ').join(' ')
      value = value.split('  ').join(' ')
      value = value.trim()
      return value
    }
  },
  computed: {
    isCollaborator () {
      let returned = false
      if (typeof this.$store.state.current_user_portal_caps != 'undefined') {
        if (this.$store.state.current_user_portal_caps != null) {
          if (null !== this.$store.state.current_user_portal_caps) returned = this.$store.state.current_user_portal_caps.can_portal_projects_see == '1'
        }
      }
      return returned
    },
    isProjectAdmin () {
      let returned = false
      if (returned == false && typeof this.$store.state.current_user_portal_caps != 'undefined') {
        if (null !== this.$store.state.current_user_portal_caps) {
          if (this.$store.state.current_user_portal_caps.can_portal_manage == '1') {
            console.log('user can_portal_manage = true : showing all projects')
            returned = true
          }
          if (this.$store.state.current_user_portal_caps.can_portal_projects_manage === '1') {
            console.log('user can_portal_projects_manage = true : showing all projects')
            returned = true
          }
        }
      }
      return returned
    },
    // ROYBON / Query url
    projectsWithoutPositionFiltered () {
      const returned = Object.entries(this.projectsWithoutPosition)
        .map(item => item[1])
        // .filter(item => Object.keys(this.projectsFiltered).includes(item.project_id))
      return returned
    },
    // ROYBON / Dynamic filtered markers
    markersFilteredLimits () {
      let lat_min = null
      let lat_max = null
      let lng_min = null
      let lng_max = null
      for (const i in this.markersFiltered) {
        const lat = parseFloat(this.markersFiltered[i].position.lat)
        const lng = parseFloat(this.markersFiltered[i].position.lng)
        if (lat < lat_min || lat_min === null) lat_min = lat
        if (lat > lat_max || lat_max === null) lat_max = lat
        if (lng < lng_min || lng_min === null) lng_min = lng
        if (lng > lng_max || lng_max === null) lng_max = lng
      }
      return {
        lat_min: lat_min,
        lat_max: lat_max,
        lng_min: lng_min,
        lng_max: lng_max, 
        center: {
          lat: (lat_max + lat_min) / 2,
          lng: (lng_max + lng_min) / 2
        }
      }
    },
    // ROYBON / Dynamic filtered markers
    markersFiltered () {
      const returned = []
      for (const i in this.projectsFiltered) {
        const p = this.projectsFiltered[i]
        if (p.project_lat !== '0' && p.project_lat !== 'null' && p.project_lat !== null) {
          returned.push({ 
            project_id: p.project_id,
            position: {
              lat: parseFloat(p.project_lat),
              lng: parseFloat(p.project_lng)
            },
            infotext: `<div class='infobox'><a href='/project/${p.project_slug}'>
            <h5>${decodeURIComponent(p.project_nicename)}</h5>
            <img src="${this.$appConfig.imgBaseUrl}/project/cover/${p.project_id}/thumb_1000_300.jpg"/>
            </a></div>`
          })
        }
      }
      return returned
    },
    // ROYBON / Query url
    query () {
      let returned = ''
      if (this.keywordQuery !== '' || this.searchQuery !== '') {
        returned = `${returned}?`
        if (this.keywordQuery !== '') {
          returned = `${returned}keyword=${this.keywordQuery}`
        }
        if (this.searchQuery !== '') {
          if (this.keywordQuery !== '') returned = `${returned}&`
          returned = `${returned}s=${this.searchQuery}`
        }
      }
      return returned
    },
    // ROYBON / Liste filtrée des projets
    projectsFiltered () {
      let returned = Object.entries(this.projects)
        .map(item => item[1])
      if (this.keywordQuery !== '') {
        returned = returned
          .filter(item => item.keywords !== null && item.keywords.includes(this.keywordQuery))
      }
      if (this.sanitizeQuery(this.searchQuery) !== '') {
        returned = returned
          .filter(item => this.sanitizeQuery(item.project_slug.toLowerCase().concat(item.project_nicename.toLowerCase()).concat(item.keywords)).includes(this.sanitizeQuery(this.searchQuery)))
      }
      return returned
    },
    // ROYBON / Liste complète des keywords
    projectsKeywords () {
      const output = []
      const keys   = []
      if (this.projects && this.projects.length > 0) {
        this.projects.forEach(function (project) {
          let keywordsString = null
          keywordsString = project['keywords']
          if (keywordsString !== null && typeof keywordsString !== 'undefined') {
            const keywordsGroup = JSON.parse(project['keywords'])
    
            if (keywordsGroup !== '[]' && keywordsGroup !== null) {
              keywordsGroup.forEach(function (keyword) {
                if (keys.indexOf(keyword) === -1) {
                  keys.push(keyword)
                  output.push(keyword)
                }
              })
            }
          }
        })
      }
      return output.sort()
    },
    // ROYBON / Liste filtrée des keywords
    projectsFilteredKeywords () {
      const output = []
      const keys   = []
      this.projectsFiltered.forEach(function (project) {
        const keywordsGroup = JSON.parse(project['keywords'])

        if (keywordsGroup !== '[]' && keywordsGroup !== null) {
          keywordsGroup.forEach(function (keyword) {
            if (keys.indexOf(keyword) === -1) {
              keys.push(keyword)
              output.push(keyword)
            }
          })
        }
      })
      return output.sort()
    },
    privatePortal () {
      if (typeof this.current_user_global_caps !== undefined && typeof this.current_user_portal_caps !== undefined) {
        if (this.current_user_global_caps !== null) {
          if (this.current_user_portal_caps !== null) {
            return (this.$store.state.AppActiveUser.user_id > 0 && this.$store.state.current_user_global_caps.can_global_access === '1' && this.$store.state.current_user_portal_caps.can_portal_access === '0')
          }  else {
            return true
          }
        }
      }  
    },
    loggedIn () {
      return (this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.uuid) !== '0')
    },
    currentTimestamp () {
      return new Date().getTime()
    },
    scrollbarTag () { 
      return this.$store.getters.scrollbarTag 
    },
    numberOfProjectsOnList () {
      return Object.keys(this.projectsFiltered).length
    },
    numberOfProjectsOnMap () {
      return Object.keys(this.markersFiltered).length
    },
    numberOfProjectsWithoutPosition () {
      return Object.keys(this.projectsFiltered).length - Object.keys(this.markersFiltered).length
    }
  },
  beforeMount () {
    if (this.$store.state.AppActiveUser != null) {
      if (typeof this.$store.state.AppActiveUser.user_id != 'undefined' && this.$store.state.AppActiveUser.user_id && parseInt(this.$store.state.AppActiveUser.uuid) != '0' && typeof this.$store.state.AppActiveUser.email_verified != 'undefined' && this.$store.state.AppActiveUser.email_verified != false) {
        // 0. charge les droits GLOBAUX du user à l'échelle de l'application
        this.updateUserGlobalCaps()
        // 1. charge le portal
        this.updatePortalInfos()
      } else {
        this.$router.push('/').catch(() => {})
      }
    }
  },
  mounted () {
    if (this.portalExists) {
      this.current_user_portal_caps = null
    }
    this.keywordQuery = this.$route.query.keyword || ''
    this.searchQuery = this.$route.query.s || ''
  },
  watch: {

    query: function () {
      history.pushState(
        {},
        null,
        `${this.$route.path}${this.query}`
      )
    },
    projectOrderBy: function () {
      this.getProjectsList()
    },
    projectOrderSens: function () {
      this.getProjectsList()
    },
    portal_id: function () {
      // 2. charge les droits du user sur ce portal
      this.getProjectsList()
      this.getUserPortalCaps()
    },
    current_user_portal_caps: function () {
      // 3. check si user peut voir la page
      this.canSeePage()
    },
    can_see_page: function () {
      // 4. si can_see_page : charge les projets
      if (this.can_see_page == true) {
        // console.log('can_see_page hook')
      }
    },
    userPortalCapsRefreshed: function () {
      // 4. si can_see_page : charge les projets
      if (this.userPortalCapsRefreshed == true) {
        this.getProjectsList()
      }
    },
    projects: function () {
      // 5. après chargement des projets : charge la carte + la liste de recheche
      this.loadProjectsOnMap()
      // 6. reporte la fin du changement
      this.loaded = true
      // 7. log l'activité du user
      logActivity.add('portal_projects', 'success')
    },
    projectsWithoutPosition () {
      this.showNoPositionAlert = true
    },
    loaded: function () {

    }
  }
}
</script>

<style lang="scss">
#demo-basic-card {
    .overlay-card {
        .vx-project__collapsible-content {
            max-height: 485px;
        }
    }
}
.limited_height{
  overflow: hidden;
}
.limited_height img{
  // min-height: 150px;
  min-width: auto;
  max-width: 100%;
  width: auto;
  height: auto;
}
.infobox img{
  width: auto;
  height: 80px;
}
.infobox h5{
  font-size: 1rem;
}
.f-width {
    width: 100%;
}
.no_position_project_link {
  color: unset;
}
.no_position_project_link:hover, .no_position_project_link:visited {
  color: unset;
  font-weight: 700;
}

</style>
