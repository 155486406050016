<template>
    <div class="grid-layout-container alignment-block">

        <template v-if="portalSubscriptionLoaded">
          <template v-if="display">
            <!-- subscription time -->
            <vs-row v-if="time" vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
              <vs-col vs-justify="space-around" vs-align="center" vs-w="8" vs-lg="8" vs-sm="7" vs-xs="12"> 
                  <p>{{$t('timeUsage')}}{{Number(subscriptionUsage.timeDaysAvailable) > 0 ? ' : '+Number((subscriptionUsage.timeUsedPercent).toFixed(1))+' %' : ''}}</p>
                  <vs-progress height="8" :percent="subscriptionUsage.timeUsedPercent" :color="getSizeColor(subscriptionUsage.timeUsedPercent)" class="block mt-1 shadow-md"></vs-progress>
              </vs-col>
              <vs-col vs-justify="space-around" vs-align="center" vs-w="3" vs-lg="3" vs-sm="4" vs-xs="12"> 
                  <p>{{$t('timeStart')}} : {{subscriptionUsage.timeStart | shortDate}}</p>
                  <p>{{$t('timeEnd')}} : {{subscriptionUsage.timeEnd | shortDate}}</p>
                  <p v-if="Number(subscriptionUsage.timeDaysAvailable) > 0">{{$t('available')}} : {{subscriptionUsage.timeDaysAvailable}} {{$t('days')}}</p>
                  <vs-alert color="danger" v-else>{{$t('subscriptionExpired')}}</vs-alert>
              </vs-col>
            </vs-row>

            <!-- disk space -->
            <vs-row v-if="disk" vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
              <vs-divider v-if="time" />
              <vs-col vs-justify="space-around" vs-align="center" vs-w="8" vs-lg="8" vs-sm="7" vs-xs="12"> 
                  <p>{{$t('spaceUsage')}} : {{ Number((subscriptionUsage.spaceUsedPercent).toFixed(1))}}%</p>
                  <vs-progress height="8" :percent="subscriptionUsage.spaceUsedPercent" :color="getSizeColor(subscriptionUsage.spaceUsedPercent)" class="block mt-1 shadow-md"></vs-progress>
              </vs-col>
              <vs-col vs-justify="space-around" vs-align="center" vs-w="3" vs-lg="3" vs-sm="4" vs-xs="12"> 
                  <p>{{$t('total')}} : {{subscriptionUsage.spaceTotal | prettyBytes}}</p>
                  <p>{{$t('used')}} : {{subscriptionUsage.spaceUsed | prettyBytes}}</p>
                  <p>{{$t('available')}} : {{subscriptionUsage.spaceAvailable | prettyBytes}}</p>
              </vs-col>
            </vs-row>

            <!-- layers usage -->
            <vs-row v-if="layers" vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
              <vs-divider v-if="time || disk" />
              <vs-col vs-justify="space-around" vs-align="center" vs-w="8" vs-lg="8" vs-sm="7" vs-xs="12"> 
                  <p>{{$t('layersUsage')}} : {{ Number((subscriptionUsage.layersUsedPercent).toFixed(1))}}%</p>
                  <vs-progress height="8" :percent="subscriptionUsage.layersUsedPercent" :color="getSizeColor(subscriptionUsage.layersUsedPercent)" class="block mt-1 shadow-md"></vs-progress>
              </vs-col>
              <vs-col vs-justify="space-around" vs-align="center" vs-w="3" vs-lg="3" vs-sm="4" vs-xs="12"> 
                  <p>{{$t('total')}} : {{subscriptionUsage.layersTotal }}</p>
                  <p>{{$t('used')}} : {{subscriptionUsage.layersUsed }}</p>
                  <p>{{$t('available')}} : {{ subscriptionUsage.layersAvailable }}</p>
              </vs-col>
            </vs-row>

            <!-- projects usage -->
            <vs-row v-if="projects" vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
              <vs-divider v-if="time || disk || layers" />
              <vs-col vs-justify="space-around" vs-align="center" vs-w="8" vs-lg="8" vs-sm="7" vs-xs="12"> 
                  <p>{{$t('projectsUsage')}} : {{ Number((subscriptionUsage.projectsUsedPercent).toFixed(1))}}%</p>
                  <vs-progress height="8" :percent="subscriptionUsage.projectsUsedPercent" :color="getSizeColor(subscriptionUsage.projectsUsedPercent)" class="block mt-1 shadow-md"></vs-progress>
              </vs-col>
              <vs-col vs-justify="space-around" vs-align="center" vs-w="3" vs-lg="3" vs-sm="4" vs-xs="12"> 
                  <p>{{$t('total')}} : {{subscriptionUsage.projectsTotal }}</p>
                  <p>{{$t('used')}} : {{subscriptionUsage.projectsUsed }}</p>
                  <p>{{$t('available')}} : {{ subscriptionUsage.projectsAvailable }}</p>
              </vs-col>
            </vs-row>

            <!-- users usage -->
            <vs-row v-if="users" vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
              <vs-divider v-if="time || disk || layers || projects" />
              <vs-col vs-justify="space-around" vs-align="center" vs-w="8" vs-lg="8" vs-sm="7" vs-xs="12"> 
                  <p>{{$t('usersUsage')}} : {{ Number((subscriptionUsage.usersUsedPercent).toFixed(1))}}%</p>
                  <vs-progress height="8" :percent="subscriptionUsage.usersUsedPercent" :color="getSizeColor(subscriptionUsage.usersUsedPercent)" class="block mt-1 shadow-md"></vs-progress>
              </vs-col>
              <vs-col vs-justify="space-around" vs-align="center" vs-w="3" vs-lg="3" vs-sm="4" vs-xs="12"> 
                  <p>{{$t('total')}} : {{subscriptionUsage.usersTotal }}</p>
                  <p>{{$t('used')}} : {{subscriptionUsage.usersUsed }}</p>
                  <p>{{$t('available')}} : {{ subscriptionUsage.usersAvailable }}</p>
              </vs-col>
            </vs-row>
          </template>
        </template>
    </div>

</template>

<script>
import axios from '@/axios.js'

export {

}

export default {
  props: {
    display: {
      type: Boolean
    },
    time: {
      type: Boolean
    },
    layers: {
      type: Boolean
    },
    disk: {
      type: Boolean
    },
    projects: {
      type: Boolean
    },
    users: {
      type: Boolean
    },
    actualisation: {   
    }
  },

  components: {
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalSubscriptionUsage: 'portal/getPortalSubscriptionUsage',

      // ROYBON / Subscription usage
      portalSubscriptionUsage: {},
      portalSubscriptionLoaded: false,

      // ROYBON / Subscription usage
      increment:0, 
      subscriptionUsage: {
        timeStart                   : null,
        timeEnd                     : null,
        timeUsedPercent             : null,
        timeDaysAvailable           : null,
        spaceUsedPercent            : null,
        spaceUsed                   : null,
        spaceTotal                  : null,
        layersTotal                 : null,
        layersUsed                  : null,
        layersAvailable             : null,
        layersUsedPercent           : null,
        projectsTotal               : null,
        projectsUsed                : null,
        projectsAvailable           : null,
        projectsUsedPercent         : null,
        usersTotal                  : null,
        usersUsed                   : null,
        usersAvailable              : null,
        usersUsedPercent            : null
      }

    }
  },
  methods: {
    // ROYBON / Obtient les infos sur l'utilisation de l'abonnement
    getSubscriptionUsage () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/${this.getPortalSubscriptionUsage}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.portalSubscriptionUsage = response.data
        })
        .catch((error)   => { console.log(error) })
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    }
  },
  computed: {
  },

  beforeMount () {
    this.getSubscriptionUsage()
  },

  created () {
  },
  mounted () {
  },

  watch: {
    actualisation: function () {
      this.getSubscriptionUsage()
    },
    portalSubscriptionUsage: function () {
      // stockage local
      this.subscriptionUsage.timeStart                    = this.portalSubscriptionUsage[0].start_access
      this.subscriptionUsage.timeEnd                      = this.portalSubscriptionUsage[0].end_access
      this.subscriptionUsage.timeUsedPercent              = parseFloat(this.portalSubscriptionUsage[0].time_percent) * 100 || 0
      this.subscriptionUsage.timeDaysAvailable            = parseInt(this.portalSubscriptionUsage[0].days_available) || 0
      this.subscriptionUsage.spaceTotal                   = parseFloat(this.portalSubscriptionUsage[0].space_total) || 0
      this.subscriptionUsage.spaceUsed                    = parseFloat(this.portalSubscriptionUsage[0].space_used) || 0
      this.subscriptionUsage.spaceAvailable               = this.subscriptionUsage.spaceTotal - this.subscriptionUsage.spaceUsed
      this.subscriptionUsage.spaceUsedPercent             = parseFloat(this.portalSubscriptionUsage[0].space_used_percent) * 100 || 0
      this.subscriptionUsage.layersTotal                  = parseFloat(this.portalSubscriptionUsage[0].layers_total) || 0
      this.subscriptionUsage.layersUsed                   = parseFloat(this.portalSubscriptionUsage[0].layers_used) || 0
      this.subscriptionUsage.layersAvailable              = this.subscriptionUsage.layersTotal - this.subscriptionUsage.layersUsed
      this.subscriptionUsage.layersUsedPercent            = parseFloat(this.portalSubscriptionUsage[0].layers_used_percent) * 100 || 0
      this.subscriptionUsage.projectsTotal                = parseFloat(this.portalSubscriptionUsage[0].projects_total) || 0
      this.subscriptionUsage.projectsUsed                 = parseFloat(this.portalSubscriptionUsage[0].projects_used) || 0
      this.subscriptionUsage.projectsAvailable            = this.subscriptionUsage.projectsTotal - this.subscriptionUsage.projectsUsed
      this.subscriptionUsage.projectsUsedPercent          = parseFloat(this.portalSubscriptionUsage[0].projects_used_percent) * 100 || 0
      this.subscriptionUsage.usersTotal                   = parseFloat(this.portalSubscriptionUsage[0].users_total) || 0
      this.subscriptionUsage.usersUsed                    = parseFloat(this.portalSubscriptionUsage[0].users_used) || 0
      this.subscriptionUsage.usersAvailable               = this.subscriptionUsage.usersTotal - this.subscriptionUsage.usersUsed
      this.subscriptionUsage.usersUsedPercent             = parseFloat(this.portalSubscriptionUsage[0].users_used_percent) * 100 || 0

      // stockage dans le store
      this.$store.commit('UPDATE_SUSBSCRIPTION_USAGE', this.subscriptionUsage)
      this.$store.dispatch('updateSubscriptionUsage', this.subscriptionUsage)

      this.portalSubscriptionLoaded = true
    }
  }
}
</script>
