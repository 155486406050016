<template>
  <!-- Project Card -->
  <vx-card class="h-20" v-if="display">
    <div slot="no-body">
      <div v-if="project.project_status === 'public'" class="small_ribbon small_ribbon-top-right">
        <feather-icon v-if="project.project_status === 'public'" icon="GlobeIcon" svgClasses="text-white p-0 w-4 h-4 ml-2 mt-2"/>
      </div>

      <img v-if="this.imageFound === true"
        :src="this.coverUrl" 
        alt="content-img" 
        class="responsive card-img-top">

      <img v-if="this.imageFound === false"
        :src="this.undefinedCoverUrl" 
        alt="content-img" 
        class="responsive card-img-top">

    </div>
    <div>
      <h5 class="break-words">{{ decodeURIComponent(project.project_nicename) }}</h5>
      <div class="block">
        <p v-if="project.project_status === 'public'"><label  class="text-sm">{{ $t('publicModel') }}</label></p>
        <p v-if="project.project_coordinate_system != null && project.project_coordinate_system != '0'"><label class="text-sm">{{ $t('georeferencedProject') }}</label></p>
      </div>
      <template v-if="project.project_status != 'public' && !forceAccessiblity">
        <label v-if="project.user_accessible === '1'" class="text-sm">{{ $t('userAccessibleModel') }}</label>
        <div v-else class="my-2">
          <feather-icon icon="SlashIcon" svgClasses="h-3 w-3" class="ml-0 mr-1" />
          <label class="text-sm py-0 my-0">{{ $t('userNotAccessibleModel') }}</label>
        </div>
      </template>
    </div>
    <div v-if="mounted && projectAccessRequestLoaded" class="flex">
      <vs-button 
        v-if="accessible"
        class="ml-auto mr-auto w-full mt-4" 
        type="border" 
        color="primary" 
        :href="'/project/' + project.project_slug">
        {{ $t('AccessButton') }}
      </vs-button>

      <template v-if="!accessible">
        <!-- demande refusée -->
        <vs-button 
          v-if="projectAccessRequest.closed == '1' && projectAccessRequest.accepted == '0'"
          disabled
          class="ml-auto mr-auto w-full mt-4" 
          type="border" 
          color="danger">
          {{ $t('RejectedRequestAccessButton') }}
        </vs-button>
        <!-- demande acceptée -->
        <vs-button 
          v-else-if="projectAccessRequest.closed == '1' && projectAccessRequest.accepted == '1'"
          class="ml-auto mr-auto w-full mt-4" 
          type="border" 
          color="primary" 
          :href="'/project/' + project.project_slug">
          {{ $t('AccessButton') }}
        </vs-button>
        <!-- demande envoyée -->
        <vs-button 
          v-else-if="projectAccessRequest.sent == '1'"
          disabled
          class="ml-auto mr-auto w-full mt-4" 
          type="border" 
          :color="this.greyColor">
          {{ $t('PendingRequestAccessButton') }}
        </vs-button>
        <!-- aucune demande envoyée -->
        <vs-button :id="'projectAccessRequest_'+this.project.project_id" class="vs-con-loading__container ml-auto mr-auto w-full mt-4"
          v-else
          type="border" 
          color="warning"
          @click.stop="sendAccessRequest()"
          :disabled="requestInProgress">
          {{ $t('RequestAccessButton') }}
        </vs-button>
      </template>

    </div>
  </vx-card>
</template>

<script>
import axios from '@/axios.js'
import logActivity from '@/logActivity.js'

export default {
  name: 'project-card',
  props: {
    display: {
      type: Boolean, 
      default: true
    },
    project: {
      type: Object,
      default: () => {}
    }, 
    forceAccessiblity: {
      type: Boolean, 
      default: false
    }
  },

  components: {
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getProjectAccessRequest: 'getProjectAccessRequest',
      createProjectAccessRequest: 'createProjectAccessRequest',

      // ROYBON / Cover image
      thumbName : 'thumb_1000_400.jpg',
      coverUrl: '', 
      undefinedCoverUrl : `${this.$appConfig.imgBaseUrl}/project/cover/undefined/thumb_1000_400.jpg`,
      imageFound: null, 

      // ROYBON / Access request
      projectAccessRequest : {},
      requestInProgress: false, 
      greyColor: '#626262',
      lightGreyColor: '#e0e0e0',

      // ROYBON / Lifecycle
      mounted: false,
      projectAccessRequestLoaded: false
    }
  },
  methods: {
    openLoadingContained (btn) {
      this.$vs.loading({
        color: 'warning',
        container: btn,
        scale: 0.45
      })
      // setTimeout(() => {
      //   this.$vs.loading.close(`${btn} > .con-vs-loading`)
      //   this.projectAccessRequest.sent = '1'
      //   this.projectAccessRequest.treated = '0'
      //   this.projectAccessRequest.accepted = '0'
      //   this.projectAccessRequest.closed = '0'
      //   this.projectAccessRequest.request_hash = 'empty'
      // }, 5000)
    },

    // ROYBON / récupère l'état de la demande d'accès
    sendAccessRequest () {
      //const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&user_id=${this.$store.state.AppActiveUser.user_id}`       
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('project_id', `${this.project.project_id}`)
      params.set('user_id', `${this.$store.state.AppActiveUser.user_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.createProjectAccessRequest}`
      this.requestInProgress = true
      const btn = `#projectAccessRequest_${this.project.project_id}`
      this.openLoadingContained(btn)
      axios.post(rimnat_api_call_url, params, {
        timeout: 60000 // les envois Sendinblue sont assez long...
      })
        .then((response) => { 
          if (response.data != null && response.data[0] != 'null') {
            this.projectAccessRequest = response.data 
            console.log('response.data')
            console.log(response.data)
            this.$vs.notify({
              color:'primary',
              text:this.$t('PendingRequestAccessButton'),
              time:4000
            })
            logActivity.add('project_access_request_created', this.request_hash)
          }
          this.requestInProgress = false
          this.$vs.loading.close(`${btn} > .con-vs-loading`)
        })
        .catch((error)   => { 
          console.log(error) 
          logActivity.add('project_access_request_creation_error', 'error')
        })
    },

    // ROYBON / récupère l'état de la demande d'accès
    // getAccessRequest () {
    //   const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&user_id=${this.$store.state.AppActiveUser.user_id}`       
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/${this.getProjectAccessRequest}.php?${params}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       if (response.data != null && response.data[0] != 'null') {
    //         this.projectAccessRequest = response.data 
    //       }
    //     })
    //     .catch((error)   => { console.log(error) })
    // },

    // ROYBON / récupère et teste l'URL de l'image
    getCoverUrl () {
      this.coverUrl = `${this.$appConfig.imgBaseUrl}/project/cover/${this.project.project_id}/${this.thumbName}`
      this.$http.get(this.coverUrl)
        .then((response) => { 
          if (response.status === 200) {
            this.imageFound = true
          } else {
            this.imageFound = false
          }
        })
        .catch(() => {
          this.imageFound = false
        })
    }
  },
  computed: {
    accessible () {
      return this.forceAccessiblity || this.project.project_status == 'public' || this.project.user_accessible == '1'
    }
    // requestSending () {
    //   let returned = false
    //   if (typeof this.projectAccessRequest.request_hash != 'undefined') returned = this.projectAccessRequest.request_hash != null && this.projectAccessRequest.sent != '1'
    //   return returned
    // },
    // requestSent () {
    //   let returned = false
    //   if (typeof this.projectAccessRequest.request_hash != 'undefined') returned = this.projectAccessRequest.sent == '1'
    //   return returned
    // },
    // requestRefused () {
    //   let returned = false
    //   if (typeof this.projectAccessRequest.request_hash != 'undefined') returned = this.projectAccessRequest.closed == '1' && this.projectAccessRequest.accepted == '0'
    //   return returned
    // },
    // requestAccepted () {
    //   let returned = false
    //   if (typeof this.projectAccessRequest.request_hash != 'undefined') returned = this.projectAccessRequest.closed == '1' && this.projectAccessRequest.accepted == '1'
    //   return returned
    // }
  },
  beforeMount () {
    if (this.project != null && typeof this.project.project_id != 'undefined' && !isNaN(this.project.project_id)) {
      this.projectAccessRequest.sent = this.project.sent || null
      this.projectAccessRequest.treated = this.project.treated || null
      this.projectAccessRequest.accepted = this.project.accepted || null
      this.projectAccessRequest.closed = this.project.closed || null
      this.projectAccessRequest.request_hash = this.project.request_hash || null
      this.projectAccessRequestLoaded = true
    }
  },
  created () {
    this.getCoverUrl()
  },
  mounted () {
    this.mounted = true
  },

  watch: {
  }
}
</script>
<style lang="scss">
    .small_ribbon {
      width: 100px;
      height: 100px;
      overflow: hidden;
      position: absolute;
    }
    .small_ribbon::before,
    .small_ribbon::after {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 2px solid rgb(139, 181, 23);
    }
    .small_ribbon span {
      position: absolute;
      display: block;
      width: 300px;
      padding: 2px 0;
      background-color: rgb(139, 181, 23);
      box-shadow: 0 5px 10px rgba(0,0,0,.5);
      color: #fff;
      font-size: 0.8rem;
      font-weight: 600;
      text-shadow: 0 2px 2px rgba(0,0,0,.5);
      text-transform: uppercase;
      text-align: center;
    }
    /* top right*/
    .small_ribbon-top-right {
      top: 0px;
      right: 0px;
    }
    .small_ribbon-top-right::before,
    .small_ribbon-top-right::after {
      border-top-color: transparent;
      border-right-color: transparent;
    }
    .small_ribbon-top-right::before {
      top: 0;
      left: 0;
    }
    .small_ribbon-top-right::after {
      bottom: 0;
      right: 0;
    }
    .small_ribbon-top-right span {
      left: -70px;
      top: 0px;
      transform: rotate(45deg);
    }
</style>
